import React from 'react';
import { Redirect } from 'react-router';
import Navigation from './Navigation.js';
import Footer from './Footer.js';
import HouseListing from './HouseListing.js';
import Page from 'react-page-loading'
import { MapContainer, TileLayer, Marker, Popup, Polygon } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import icon from 'leaflet/dist/images/marker-icon.png';
import shadow from 'leaflet/dist/images/marker-shadow.png';

class Zone extends React.Component {
    constructor() {
        super();
        this.state = { results: null };
        this.markerRefs = [];
    }

    async componentDidMount() {
        
    }
    
    render() {
        const polygon = [
            [45.85194409408966 + 0.0008, 9.391891363975596],
            [45.85194409408966, 9.391891363975596 + 0.0011],
            [45.85194409408966 + 0.009, 9.391891363975596 + 0.014],
            [45.85194409408966 + 0.009, 9.391891363975596 + 0.003],
        ]
        return (
            <>
                <Navigation />
                    <div className="properties-section-body content-area-16">
                        <div className="container">
                            <div className="row mt-1 bg-white sidebar-widget">
                                    <div className="heading-properties clearfix mb-30">
                                        <div className="pull-left">
                                            <h3 style={{ color: "#95c41f" }}>Prova microzona</h3>
                                            <p>
                                            Report del 27 Novembre 2021
                                            </p>
                                        </div>
                                        <div className="pull-right">
                                            <h3></h3>
                                        </div>
                                    </div>
                                    <div className="map mb-30">
                                        <div className="map__container">
                                            <MapContainer style={{ height: "450px", width: "100%" }} center={[45.85194409408966 + 0.008, 9.391891363975596 + 0.005]} zoom={16}>
                                                <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                                <Polygon pathOptions={{ color: 'purple' }} positions={polygon} />
                                                <Marker position={[45.85194409408966 + 0.008, 9.391891363975596 + 0.005]}>
                                                    <Popup>
                                                        <p>Appartamento in Via Roma 51</p>
                                                        <p align="center"><img src="https://images-1.casa.it/700x500/listing/6fdd6d87e06dde16befffe8f1f11575d" width="100px" height="100px"/></p>
                                                        <a href="https://micromap.it/listing/66023/543352" target="_blank">Visualizza l'immobile</a>
                                                    </Popup>
                                                </Marker>
                                                <Marker position={[45.85194409408966 + 0.0079, 9.391891363975596 + 0.004]}>
                                                    <Popup>
                                                        <p>Appartamento in Via Roma 51</p>
                                                        <p align="center"><img src="https://images-1.casa.it/700x500/listing/6fdd6d87e06dde16befffe8f1f11575d" width="100px" height="100px"/></p>
                                                        <a href="https://micromap.it/listing/66023/543352" target="_blank">Visualizza l'immobile</a>
                                                    </Popup>
                                                </Marker>
                                                <Marker position={[45.85194409408966 + 0.0073, 9.391891363975596 + 0.004]}>
                                                    <Popup>
                                                        <p>Appartamento in Via Roma 51</p>
                                                        <p align="center"><img src="https://images-1.casa.it/700x500/listing/6fdd6d87e06dde16befffe8f1f11575d" width="100px" height="100px"/></p>
                                                        <a href="https://micromap.it/listing/66023/543352" target="_blank">Visualizza l'immobile</a>
                                                    </Popup>
                                                </Marker>
                                                <Marker position={[45.85194409408966 + 0.0072, 9.391891363975596 + 0.005]}>
                                                    <Popup>
                                                        <p>Appartamento in Via Roma 51</p>
                                                        <p align="center"><img src="https://images-1.casa.it/700x500/listing/6fdd6d87e06dde16befffe8f1f11575d" width="100px" height="100px"/></p>
                                                        <a href="https://micromap.it/listing/66023/543352" target="_blank">Visualizza l'immobile</a>
                                                    </Popup>
                                                </Marker>
                                            </MapContainer>
                                        </div>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table text-center table-striped table-hover">
                                            <thead>
                                                <tr>
                                                    <th style={{ color: "#95c41f", border: "none" }}>Tipologia</th>
                                                    <th style={{ color: "#95c41f", border: "none" }}>Indirizzo</th>
                                                    <th style={{ color: "#95c41f", border: "none" }}>Città</th>
                                                    <th style={{ color: "#95c41f", border: "none" }}>Locali</th>
                                                    <th style={{ color: "#95c41f", border: "none" }}>Superficie </th>
                                                    <th style={{ color: "#95c41f", border: "none" }}>Prezzo (mq)</th>
                                                    <th style={{ color: "#95c41f", border: "none" }}>Prezzo</th>
                                                    <th style={{ color: "#95c41f", border: "none" }}>Discostamento</th>
                                                    <th style={{ color: "#95c41f", border: "none" }}>Data</th>
                                                    <th style={{ color: "#95c41f", border: "none" }}>Azioni</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Appartamento</td>
                                                    <td>Via Roma 51</td>
                                                    <td>Lecco</td>
                                                    <td>3</td>
                                                    <td>87 mq</td>
                                                    <td>3.448,27 €</td>
                                                    <td>300.000 €</td>
                                                    <td style={{ color: "red" }}>+13 %</td>
                                                    <td>12 Novembre 2021</td>
                                                    <td><a href="https://micromap.it/listing/66023/543352" target="_blank"><i className="fa fa-eye" style={{ paddingLeft: "3px", paddingRight: "3px" }}></i></a> <i className="fa fa-star" style={{ paddingLeft: "3px", paddingRight: "3px" }}></i></td>
                                                </tr>
                                                <tr>
                                                    <td>Appartamento</td>
                                                    <td>Via Roma 51</td>
                                                    <td>Lecco</td>
                                                    <td>3</td>
                                                    <td>87 mq</td>
                                                    <td>3.448,27 €</td>
                                                    <td>300.000 €</td>
                                                    <td style={{ color: "green" }}>-8 %</td>
                                                    <td>12 Novembre 2021</td>
                                                    <td><a href="https://micromap.it/listing/66023/543352" target="_blank"><i className="fa fa-eye" style={{ paddingLeft: "3px", paddingRight: "3px" }}></i></a> <i className="fa fa-star" style={{ paddingLeft: "3px", paddingRight: "3px" }}></i></td>
                                                </tr>
                                                <tr>
                                                    <td>Appartamento</td>
                                                    <td>Via Roma 51</td>
                                                    <td>Lecco</td>
                                                    <td>3</td>
                                                    <td>87 mq</td>
                                                    <td>3.448,27 €</td>
                                                    <td>300.000 €</td>
                                                    <td style={{ color: "green" }}>-8 %</td>
                                                    <td>12 Novembre 2021</td>
                                                    <td><a href="https://micromap.it/listing/66023/543352" target="_blank"><i className="fa fa-eye" style={{ paddingLeft: "3px", paddingRight: "3px" }}></i></a> <i className="fa fa-star" style={{ paddingLeft: "3px", paddingRight: "3px" }}></i></td>
                                                </tr>
                                                <tr>
                                                    <td>Appartamento</td>
                                                    <td>Via Roma 51</td>
                                                    <td>Lecco</td>
                                                    <td>3</td>
                                                    <td>87 mq</td>
                                                    <td>3.448,27 €</td>
                                                    <td>300.000 €</td>
                                                    <td style={{ color: "red" }}>+23 %</td>
                                                    <td>12 Novembre 2021</td>
                                                    <td><a href="https://micromap.it/listing/66023/543352" target="_blank"><i className="fa fa-eye" style={{ paddingLeft: "3px", paddingRight: "3px" }}></i></a> <i className="fa fa-star" style={{ paddingLeft: "3px", paddingRight: "3px" }}></i></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </>
        );
    }
}

export default Zone;