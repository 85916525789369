import React from 'react';

class Popular extends React.Component {
    render() {
        return (
            <>
                <div className="categories content-area-12">
                    <div className="container">
                        <div className="main-title">
                            <h1>Ricerche popolari</h1>
                        </div>
                        <div className="clearfix"></div>
                        <div className="row wow">
                            <div className="col-lg-8 col-md-7 col-sm-12">
                                <div className="row">
                                    <div className="col-sm-6 col-pad wow fadeInLeft delay-04s">
                                        <div className="category cey2">
                                            <div className="category_bg_box cat-1-bg">
                                                <div className="category-overlay">
                                                    <div className="cc3">
                                                        <div className="category-subtitle">Più di 1000 proprietà</div>
                                                        <div className="info">
                                                            <h4>
                                                                <a href="#">Roma</a>
                                                            </h4>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-pad wow fadeInLeft delay-04s">
                                        <div className="category cey2">
                                            <div className="category_bg_box cat-2-bg">
                                                <div className="category-overlay">
                                                    <div className="cc3">
                                                        <div className="category-subtitle">Più di 1000 proprietà</div>
                                                        <div className="info">
                                                            <h4>
                                                                <a href="#">Napoli</a>
                                                            </h4>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-pad wow fadeInUp delay-04s">
                                        <div className="category cey2">
                                            <div className="category_bg_box cat-7-bg">
                                                <div className="category-overlay">
                                                    <div className="cc3">
                                                        <div className="category-subtitle">Più di 1000 proprietà</div>
                                                        <div className="info">
                                                            <h4>
                                                                <a href="#">Torino</a>
                                                            </h4>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-pad wow fadeInUp delay-04s">
                                        <div className="category cey2">
                                            <div className="category_bg_box cat-6-bg">
                                                <div className="category-overlay">
                                                    <div className="cc3">
                                                        <div className="category-subtitle">Più di 1000 proprietà</div>
                                                        <div className="info">
                                                            <h4>
                                                                <a href="#">Como</a>
                                                            </h4>
                                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5 col-sm-12 col-pad wow fadeInRight delay-04s">
                                <div className="category cey2">
                                    <div className="category_bg_box category_long_bg cat-3-bg">
                                        <div className="category-overlay">
                                            <div className="cc3">
                                                <div className="category-subtitle">Più di 1000 proprietà</div>
                                                <div className="info">
                                                    <h4>
                                                        <a href="#">Milano</a>
                                                    </h4>
                                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Popular;