import React from 'react';
import Logo from './assets/img/logo.png';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';


class Register extends React.Component {
    constructor(props) {
        super(props);
        this.signUp = this.signUp.bind(this);
        this.state = { error: 0, submitted: false, email: "", fetching: false };
    }

    async signUp(e) {
        e.preventDefault();
        const name = e.target.name.value;
        const surname = e.target.surname.value;
        const email = e.target.email.value;
        const phone = e.target.phone.value.replaceAll(" ", "").replace("+39", "");
        console.log(phone);
        const password = e.target.password.value;
        const confirmPassword = e.target.confirmPassword.value;

        if (name == "" || surname == "" || email == "" || phone == "" || password == "" || confirmPassword == "") {
            this.setState({ error: 4 });
            return;
        }

        if (password.length < 8) {
            this.setState({ error: 7 });
            return;
        }

        if (password != confirmPassword) {
            this.setState({ error: 3 });
            return;
        }

        const verPhone = parseInt(phone);

        if (verPhone < 3000000000 || verPhone >= 4000000000) {
            this.setState({ error: 5 });
            return;
        }

        const mailRegExp = new RegExp(/^([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?)$/);
        
        if (!mailRegExp.test(email)) {
            this.setState({ error: 6 });
            return;
        }

        this.setState({ fetching: true });
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "action": "register",
                "name": name,
                "surname": surname,
                "email": email,
                "phone": phone,
                "password": password
            })
        };
        
        const response = await fetch('https://api.micromap.it/auth', requestOptions);
        const result = await response.json();

        if (!("status" in result)) {
            NotificationManager.error("Qualcosa è andato storto: aggiorna la pagina o riprova più tardi!", '', 5000, () => {});
            this.setState({ fetching: false });
        } else {
            if ("message" in result && result["message"] == "email_or_phone_already_used") {
                this.setState({ error: 1, fetching: false });
            } else if (result["status"] == "ok") {
                this.setState({ error: 2, submitted: true, email: email, fetching: false });
            } else {
                NotificationManager.error("Qualcosa è andato storto: aggiorna la pagina o riprova più tardi!", '', 5000, () => {});
                this.setState({ fetching: false });
            }
        }
    }

    render() {
        return (
            <>
                <div className="login-section">
                    <div className="form-content-box">
                        <div className="details">
                            <div className="logo">
                                <a href="/">
                                    <img src={Logo} alt="logo" />
                                </a>
                            </div>
                            <div className="clearfix"></div>
                            {!this.state.submitted && <h3>Registrati gratuitamente</h3>}
                            {this.state.submitted && <h3>Registrazione effettuata</h3>}
                            {!this.state.submitted && <form onSubmit={this.signUp}>
                                <div className="form-group">
                                    <input id="form-name" type="text" name="name" className="input-text" placeholder="Nome" />
                                </div>
                                <div className="form-group">
                                    <input id="form-surname" type="text" name="surname" className="input-text" placeholder="Cognome" />
                                </div>
                                <div className="form-group">
                                    <input id="form-email"  name="email" className="input-text" placeholder="Indirizzo email" />
                                </div>
                                <div className="form-group">
                                    <input id="form-phone"  name="phone" className="input-text" placeholder="Numero di telefono" />
                                </div>
                                <div className="form-group">
                                    <input id="form-password" type="password" name="password" className="input-text" placeholder="Password" />
                                </div>
                                <div className="form-group">
                                    <input id="form-password" type="password" name="confirmPassword" className="input-text" placeholder="Conferma password" />
                                </div>
                                {this.state.error == 1 && <div className="alert alert-danger" role="alert">
                                    Indirizzo email o numero di telefono giù utilizzati
                                </div>}
                                {this.state.error == 3 && <div className="alert alert-danger" role="alert">
                                    Le password non coincidono
                                </div>}
                                {this.state.error == 4 && <div className="alert alert-danger" role="alert">
                                    Ci sono uno o più campi vuoti
                                </div>}
                                {this.state.error == 5 && <div className="alert alert-danger" role="alert">
                                    Numero di telefono invalido
                                </div>}
                                {this.state.error == 6 && <div className="alert alert-danger" role="alert">
                                    Indirizzo email non valido
                                </div>}
                                {this.state.error == 7 && <div className="alert alert-danger" role="alert">
                                    La password deve avere almeno 8 caratteri
                                </div>}
                                <div className="form-group">
                                    <button type="submit" className="button-md button-theme btn-block btn-3">{!this.state.fetching ? "Registrati" : <i className="fa fa-circle-o-notch fa-spin"></i>}</button>
                                </div>
                            </form>}
                            {this.state.error == 2 && <div className="alert alert-success" role="alert">
                                Abbiamo inviato una mail a {this.state.email}. Clicca sul link che hai ricevuto per attivare il tuo account!
                            </div>}
                        </div>
                        <div className="footer">
                            {!this.state.submitted && <span>
                                Sei già registrato? <a href="/login">Login qui</a>
                            </span>}
                            {this.state.submitted && <span>
                                Effettua il <a href="/login">login qui</a>
                            </span>}
                        </div>
                    </div>
                </div>
                <NotificationContainer />
            </>
        );
    }
}

export default Register;