import React from 'react';
import { Redirect } from 'react-router';
import Navigation from './Navigation.js';
import Footer from './Footer.js';
import HouseListing from './HouseListing.js';
import Filters from './Filters.js';
import Page from 'react-page-loading'
import { tupleExpression } from '@babel/types';

class Results extends React.Component {
    constructor() {
        super();
        this.state = { results: null };
    }

    async componentDidMount() {
        const provinces = ["CH","AQ","PE","TE","MT","PZ","CZ","CS","KR","RC","VV", "AV", "BN", "CE", "NA", "SA", "BO", "FE", "FC", "MO", "PR", "PC", "RA", "RE", "RN", "GO", "PN", "TS", "UD", "FR", "LT", "RI", "RM", "VT", "GE", "IM", "SP", "SV", "BG", "BS", "CO", "CR", "LC", "LO", "MN", "MI", "MB", "PV", "SO", "VA", "AN", "AP", "FM", "MC", "PU", "CB", "IS", "AL", "AT", "BI", "CN", "NO", "TO", "VB", "VC", "BA", "BT", "BR", "FG", "LE", "TA", "CA", "NU", "OR", "SS", "SU", "AG", "CL", "CT", "EN", "ME", "PA", "RG", "SR", "TP", "AR", "FI", "GR", "LI", "LU", "MS", "PI", "PT", "PO", "SI", "BZ", "TN", "PG", "TR", "AO", "BL", "PD", "RO", "TV", "VE", "VR", "VI"];
        const queryParams = new URLSearchParams(window.location.search);
        const searchProvince = this.props.match.params.id.toUpperCase();
        const searchContract = this.props.match.params.contract;
        const searchPage = this.props.match.params.page;
        let searchType = queryParams.get('type');
        let searchPriceRange = queryParams.get('price');
        let searchSurfaceRange = queryParams.get('surface');
        let searchRoomsRange = queryParams.get('rooms');
        let searchOrderBy = queryParams.get('order');

       // if (se)
        
        const searchKey = 'search:' + searchContract + ':' + searchProvince + ':' + (searchType || "all_types") + ':' + 
        (searchPriceRange || "all_prices") + ':' + (searchSurfaceRange || "all_surfaces") + ':' + (searchRoomsRange || "all_rooms") + ':' + (searchOrderBy || "newest") + ':' + searchPage;
        const pagesKey = 'search-pages:' + searchContract + ':' + searchProvince + ':' + (searchType || "all_types") + ':' + 
        (searchPriceRange || "all_prices") + ':' + (searchSurfaceRange || "all_surfaces") + ':' + (searchRoomsRange || "all_rooms");
        const results = localStorage.getItem(searchKey);
        const pagesResult = localStorage.getItem(pagesKey);
        var numPages = 0;

        if (!provinces.includes(searchProvince)) {
            this.setState({ results: false });
            return;
        }
        
        let pages = JSON.parse(pagesResult);

        console.log('uaglio', pagesResult);

        if (pagesResult == null || (pagesResult != null && (Date.now() - pages["ts"] >= 600000))) {
            var prequest = {
                "action": "get_pages", 
                "province": String(searchProvince), 
                "contract": String(searchContract) 
            }

            if (searchType) {
                prequest["type"] = searchType;
            }

            if (searchPriceRange) {
                prequest["price"] = searchPriceRange;
            }

            if (searchSurfaceRange) {
                prequest["surface"] = searchSurfaceRange;
            }

            if (searchRoomsRange) {
                prequest["rooms"] = searchRoomsRange;
            }

            if (searchOrderBy) {
                prequest["order"] = searchOrderBy;
            }

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(prequest)
            };
    
            await fetch('https://api.micromap.it/search', requestOptions)
                .then(res => { 
                    if (res.status >= 300 && res.status < 200) {
                        this.setState({ results: false });
                    } else {
                        return res.json();
                    }
                }).then(result => {
                    if (result && "pages" in result) {
                        console.log(result);
                        localStorage.setItem(pagesKey, JSON.stringify({ "data": result, "ts": Date.now() }));
                        numPages = result["pages"];
                    }
                });
        } else {
            numPages = pages["data"]["pages"];
        }

        console.log('numPages', numPages);

        if (results != null) {
            let search = JSON.parse(results);

            if (Date.now() - search["ts"] < 600000) {
                this.setState({ results: search["data"], pages: numPages });
                return;
            }
        }

        var request = {
            "province": String(searchProvince), 
            "contract": String(searchContract),
            "page": Number(searchPage),
        }

        if (searchType) {
            request["type"] = searchType;
        }

        if (searchPriceRange) {
            request["price"] = searchPriceRange;
        }

        if (searchSurfaceRange) {
            request["surface"] = searchSurfaceRange;
        }

        if (searchRoomsRange) {
            request["rooms"] = searchRoomsRange;
        }

        if (searchOrderBy) {
            request["order"] = searchOrderBy;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(request)
        };

        fetch('https://api.micromap.it/search', requestOptions)
            .then(res => { 
                if (res.status >= 300 && res.status < 200) {
                    this.setState({ results: false });
                } else {
                    return res.json();
                }
            }).then(result => {
                if (result && "listings" in result) {
                    console.log(result);
                    localStorage.setItem(searchKey, JSON.stringify({ "data": result, "ts": Date.now() }));
                    this.setState({ results: result, pages: numPages });
                }
            });
    }
    
    render() {
        const queryParams = new URLSearchParams(window.location.search);
        const searchProvince = this.props.match.params.id;
        const searchContract = this.props.match.params.contract;
        let searchType = queryParams.get('type');
        let searchPriceRange = queryParams.get('price');
        let searchSurfaceRange = queryParams.get('surface');
        let searchRoomsRange = queryParams.get('rooms');
        let searchOrderBy = queryParams.get('order');

        if (this.state.results == null) {
            return <Page loader={"rotate-spin"} color={"#95c41f"} size={8} />;
        }

        if (this.state.results === false){
            return <Redirect to="/pagina-non-trovata"  />;
        }

        console.log('boh', this.state.pages);
        return (
            <>
                <Navigation />
                <div className="properties-section-body content-area-16">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-8 col-xs-12 col-md-push-4">
                                <div className="clearfix"></div>
                                {this.state.results.listings.map((item, i) => <HouseListing id={item.id} province={item.province} postcode={item.postcode} key={item.url} mq={item.surface} rooms={item.rooms} pic={item.pics[0]} price={item.price} city={item.city} address={item.address} site={item.site} deal={false} date={item.created_at} type={item.type}/> )}
                                {this.state.results.listings.length ? <div className="text-center">
                                    <nav aria-label="Page navigation">
                                        <ul className="pagination">
                                            {this.props.match.params.page != 1 && (<li key="prev">
                                                <a href={"/results/" + searchContract + "/" + searchProvince + "/" + String(this.props.match.params.page - 1) + '?' + queryParams.toString()} aria-label="Previous">
                                                    <span aria-hidden="true"><i className="fa fa-angle-left" /></span>
                                                </a>
                                            </li>)}
                                            <li key="1" className={this.props.match.params.page == 1 ? "active" : ""}><a href={"/results/" + searchContract + "/" + searchProvince + "/1" + '?' + queryParams.toString()}>1 <span className="sr-only">(current)</span></a></li>
                                            {this.state.pages >= 2 && (<li key="2" className={this.props.match.params.page == 2 ? "active" : ""}><a href={"/results/" + searchContract + "/" + searchProvince + "/2" + '?' + queryParams.toString()}>2</a></li>)}
                                            {this.props.match.params.page != 3 && this.props.match.params.page < (this.state.pages - 1) && (this.state.pages >= 2) && (<li key="2"><a>...</a></li>)}
                                            {this.props.match.params.page > 2 && this.props.match.params.page < (this.state.pages - 1)  && (<li key="5" className="active"><a href={"/results/" + searchContract + "/" + searchProvince + "/" + this.props.match.params.page + '?' + queryParams.toString()}>{this.props.match.params.page}</a></li>)}
                                            {this.props.match.params.page != (this.state.pages - 2) && this.props.match.params.page > 2 && (<li key="2"><a>...</a></li>)}
                                            {this.state.pages >= 2 && (<li key="3" className={this.props.match.params.page == this.state.pages - 1 ? "active" : ""}><a href={"/results/" + searchContract + "/" + searchProvince + "/" + String(this.state.pages - 1) + '?' + queryParams.toString()}>{this.state.pages - 1}</a></li>)}
                                            {this.state.pages >= 2 && (<li key="3" className={this.props.match.params.page == this.state.pages ? "active" : ""}><a href={"/results/" + searchContract + "/" + searchProvince + "/" + String(this.state.pages) + '?' + queryParams.toString()}>{this.state.pages}</a></li>)}
                                            {this.props.match.params.page != this.state.pages && (<li key="next">
                                                <a href={"/results/" + searchContract + "/" + searchProvince + "/" + String(this.props.match.params.page - (-1)) + '?' + queryParams.toString()} aria-label="Next">
                                                    <span aria-hidden="true"><i className="fa fa-angle-right" /></span>
                                                </a>
                                            </li>)}
                                        </ul>
                                    </nav>
                                </div> : ""}
                            </div>
                            <Filters />
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        );
    }
}

export default Results;