import React from 'react';
import Logo from './assets/img/logo.png';
import jwt_decode from "jwt-decode";

class Navbar extends React.Component {
    constructor(props) {
        super(props);
        const authToken = localStorage.getItem("authToken");

        if (authToken == null) {
            this.state = { sideBarActive: false, userInfo: null };
        } else {
            this.state = { sideBarActive: false, userInfo: jwt_decode(authToken) };
        }
    }

    render() {
        return (
            <div className="site-nav">
                <header className="main-header">
                    <div className="container">
                        <nav className="navbar navbar-default">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle collapsed" id="drawer" aria-expanded="false" onClick={() => this.setState({ sideBarActive: true })}>
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <a href="/" className="logo">
                                    <img src={Logo} alt="logo" />
                                </a>
                            </div>
                            <div className="navbar-collapse collapse" role="navigation" aria-expanded="true" id="app-navigation">
                                <ul className="nav navbar-nav">
                                    <li className="active">
                                        <a tabIndex="0" href="#" aria-expanded="false">
                                            Trova case
                                        </a>
                                    </li>
                                    <li className="dropdown">
                                        <a tabIndex="0" href="#" data-toggle="dropdown" aria-expanded="false">
                                            Ricerche popolari <span className="caret"></span>
                                        </a>
                                        <ul className="dropdown-menu">
                                            <li><a href="agent-listing-grid.html">Milano</a></li>
                                            <li><a href="agent-listing-grid.html">Roma</a></li>
                                            <li><a href="agent-listing-grid.html">Napoli</a></li>
                                            <li><a href="agent-listing-grid.html">Torino</a></li>
                                            <li><a href="agent-listing-grid.html">Como</a></li>
                                            <li><a href="agent-listing-grid.html">Altre province...</a></li>
                                        </ul>
                                    </li>
                                    <li className="dropdown">
                                        <a tabIndex="0" href="#" data-toggle="dropdown" data-submenu="" aria-expanded="false">
                                            Blog
                                        </a>
                                    </li>
                                </ul>
                                <ul className="nav navbar-nav navbar-right rightside-navbar">
                                    {this.state.userInfo == null ? 
                                        <React.Fragment>
                                            <li style={{ paddingRight: 5 + 'px' }}>
                                                <a href="/login" className="button btn-3">
                                                    Accedi
                                                </a>
                                            </li>
                                            <li>
                                                <a href="/register" className="button btn-3">
                                                    Registrati
                                                </a>
                                            </li>
                                        </React.Fragment> : 
                                        <li style={{ paddingRight: 5 + 'px' }}>
                                            <a href="/dashboard" className="button btn-3">
                                                Dashboard
                                            </a>
                                        </li>
                                    }
                                </ul>
                            </div>
                        </nav>
                    </div>
                </header>
                <nav id="sidebar" className={this.state.sideBarActive ? 'nav-sidebar active': 'nav-sidebar'} >
                    <div id="dismiss" onClick={() => this.setState({ sideBarActive: false })}>
                        <i className="fa fa-close"></i>
                    </div>
                    <div className="sidebar-inner">
                        <div className="sidebar-logo">
                            <img src={Logo} alt="sidebarlogo" />
                        </div>
                        <div className="sidebar-navigation">
                            <ul className="menu-list">
                                <li>
                                    <a href="#" className="active pt0">Trova case</a>
                                </li>
                                <li>
                                    <a href="#">Le tue ricerche</a>
                                </li>
                                <li>
                                    <a href="#">Ricerche popolari</a>
                                </li>
                                <li>
                                    <a href="#">Blog</a>
                                </li>
                                <li>
                                    <a href="/login">Accedi</a>
                                </li>
                                <li>
                                    <a className="active" href="/register">Registrati</a>
                                </li>
                            </ul>
                        </div>
                        <div className="get-social">
                            <h3 className="heading">Trovaci sui social</h3>
                            <a href="#" className="facebook-bg">
                                <i className="fa fa-facebook"></i>
                            </a>
                            <a href="#" className="twitter-bg">
                                <i className="fa fa-twitter"></i>
                            </a>
                            <a href="#" className="google-bg">
                                <i className="fa fa-google"></i>
                            </a>
                            <a href="#" className="linkedin-bg">
                                <i className="fa fa-linkedin"></i>
                            </a>
                        </div>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Navbar;