import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <>
                <footer className="main-footer clearfix">
                    <div className="container">
                        <div className="footer-info">
                            <div className="row">
                                <div className="col-lg-4 col-md-3 col-sm-6 col-xs-12">
                                    <div className="footer-item fi2">
                                        <div className="main-title-2">
                                            <h1>Contattaci</h1>
                                        </div>
                                        <p>
                                            Se avete bisogno di maggiori informazioni o per qualsiasi altro motivo, non esitate a contattarci
                                        </p>
                                        <ul className="personal-info">
                                            <li>
                                                <i className="fa fa-envelope"></i>
                                                <a href="mailto:support@micromap.it">support@micromap.it</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                    <div className="footer-item">
                                        <div className="main-title-2">
                                            <h1>Links</h1>
                                        </div>
                                        <ul className="links">
                                            <li>
                                                <a href="index.html">Home</a>
                                            </li>
                                            <li>
                                                <a href="about.html">Le tue ricerche</a>
                                            </li>
                                            <li>
                                                <a href="contact.html">Contattaci</a>
                                            </li>
                                            <li>
                                                <a href="blog-single-sidebar-right.html">Luoghi popolari</a>
                                            </li>
                                            <li>
                                                <a href="blog-single-sidebar-right.html">Blog</a>
                                            </li>
                                            <li>
                                                <a href="properties-list-rightside.html">Termini di servizio</a>
                                            </li>
                                            <li>
                                                <a href="properties-grid-rightside.html">Privacy policy</a>
                                            </li>
                                            <li>
                                                <a href="properties-details.html">Cookie policy</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="footer-item">
                                        <div className="main-title-2">
                                            <h1>Newsletter</h1>
                                        </div>
                                        <div className="newsletter clearfix">
                                            <p>Iscriviti alla nostra newsletter gratuita per ricevere notifiche su nuovi articoli pubblicati. Puoi disiscriverti quando vuoi</p>
                                            <form action="#" method="post">
                                                <div className="form-group mb-0">
                                                    <input className="nsu-field" id="nsu-email-0" type="text" name="email" placeholder="Email Address" required="" />
                                                    <button type="submit" className="btn button-theme"><i className="fa fa-paper-plane"></i></button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="copy-right">
                    <div className="container">
                        <div className="row clearfix">
                            <div className="col-md-8 col-sm-12">
                                <p>&copy;  2021 <a href="http://themevessel.com/" target="_blank">Micromap</a>. Tutti i diritti riservati. P.IVA 11194240963</p>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <ul className="social-list clearfix">
                                    <li>
                                        <a href="#" className="facebook-bg">
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="twitter-bg">
                                            <i className="fa fa-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="linkedin-bg">
                                            <i className="fa fa-linkedin"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="google-bg">
                                            <i className="fa fa-google-plus"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#" className="rss-bg">
                                            <i className="fa fa-rss"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Footer;