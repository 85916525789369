import React from 'react';
import Select from 'react-select';
import BackgroundPicture from './assets/img/img-2.png';

const kindOptions = [
    { value: 'vendita', label: 'Vendita' },
    { value: 'affitto', label: 'Affitto' },
];

const typeOptions = [
    { value: 'appartamento', label: 'Appartamento' },
    { value: 'casa', label: 'Casa indipendente' },
    { value: 'villa', label: 'Villa' },
    { value: 'ufficio', label: 'Ufficio' },
];

const provinceOptions = [
    { value: 'mi', label: 'Milano' },
    { value: 'mb', label: 'Monza Brianza' },
    { value: 'lc', label: 'Lecco' },
    { value: 'co', label: 'Como' },
];

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        boxShadow: 'none',
        border: "none"
    }),
    control: (provided, state) => ({
        ...provided,
        border: "none",
        boxShadow: 'none'
    }),

};


class Banner extends React.Component {
    state = {
        redirect: false
    };

    async handleSubmit(event) {
        event.preventDefault();
        const data = new FormData(event.target);

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "province": "CH", "page": 1 })
        };

        const response = await fetch('https://api.micromap.it/search', requestOptions).catch((error) => {
            console.log(error)
        });
          
        const res = await response.json();
        console.log(res);
        localStorage.setItem('58247438', JSON.stringify({ 'data': res["listings"], 'ts': Date.now() }));
        window.location.href = '/results/58247438';
    }

    render() {
        return (
            <div className="banner b6">
                <div id="carousel-example-generic" className="carousel slide" data-ride="carousel">
                    <div className="carousel-inner" role="listbox">
                        <div className="item item-100vh active">
                            <img src={BackgroundPicture} alt="banner-slider-1" />
                            <div className="carousel-caption banner-slider-inner">
                                <div className="banner-content container banner-content-left">
                                    <h1>Trova le migliori case sul web </h1>
                                    <p>Con una sola ricerca trovi annunci da tutte le piattaforme di vendita immobiliare, senza duplicati e stress 🎉</p>
                                    <div className="tab-btn banner-tab-btn">
                                        <div className="tab-btn-inner">
                                            <ul className="nav nav-tabs">
                                                <li className="active">
                                                    <a href="/">Provincia</a>
                                                </li>
                                                <li>
                                                    <a href="/draw">Disegna</a>
                                                </li>
                                            </ul>
                                            <div className="tab-content">
                                                <div className="tab-pane active" id="search-province">
                                                    <div className="search-area">
                                                        <div className="search-area-inner">
                                                            <form onSubmit={this.handleSubmit}>
                                                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-pad2">
                                                                    <div className="form-group">
                                                                        <Select styles={customStyles} defaultValue={{ label: 'Vendita', value: 'vendita' }} options={kindOptions} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-pad2">
                                                                    <div className="form-group">
                                                                        <Select styles={customStyles} defaultValue={{ label: 'Appartamento', value: 'appartamento' }} options={typeOptions} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 col-pad2">
                                                                    <div className="form-group">
                                                                        <Select styles={customStyles} placeholder={"Provincia"} options={provinceOptions} />
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 col-pad2 cp3">
                                                                    <div className="form-group">
                                                                        <button type="submit" className="search-button">Avvia ricerca</button>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Banner;