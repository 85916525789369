import React from 'react';
import Navigation from './Navigation.js';
import SearchForm from './SearchForm.js';
import Footer from './Footer.js';
import Popular from './Popular.js';

class Search extends React.Component {
    render() {
        return (
            <div className="search-page">
                <Navigation />
                <SearchForm />
                <Popular />
                <Footer />
            </div>
        );
    }
}

export default Search;