import React from 'react';
import Select from 'react-select';
import Slider from './Slider.js'

const contractOptions = [
    { value: 'selling', label: 'Vendita' },
    { value: 'renting', label: 'Affitto' },
];

const typeOptions = [
    { value: 'apartment', label: 'Appartamento' },
    { value: 'villa', label: 'Villa' },
];

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        border: '1px solid #95c41f',
        backgroundColor: state.isFocused ? "green" : "white",
        boxShadow: 'none'
    }),
    control: (provided, state) => ({
        ...provided,
        borderColor: "lightgrey",
        "&:hover": {
            border: "1px solid #95c41f"
        },
        boxShadow: 'none'
    }),

};


class Filters extends React.Component {
    sliders = {};

    constructor(props) {
        super(props);
        this.printout.bind(this);
    }

    printout(e) {
        e.preventDefault();
        console.log(this.sliders);
    }

    render() {
        return (
            <div className="col-lg-4 col-md-4 col-xs-12 col-md-pull-8">
                <div className="sidebar-widget">
                    <div className="main-title-2">
                        <h1>Filtri sulla ricerca</h1>
                    </div>
                    <form onSubmit={this.printout}>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <Select styles={customStyles} placeholder={"Tipologia"} options={typeOptions} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-6">
                                <div className="form-group">
                                    <Select styles={customStyles} placeholder={"Vendita"} options={contractOptions} />
                                </div>
                            </div>
                        </div>
                        <div className="range-slider">
                            <label>Locali</label>
                            <Slider filterSearch={this} name={"rooms"} minValue={1} maxValue={5} />
                            <div className="clearfix"></div>
                        </div>
                        <div className="range-slider">
                            <label>Metri quadri</label>
                            <Slider filterSearch={this} name={"surface"} minValue={1} maxValue={200} />
                            <div className="clearfix"></div>
                        </div>
                        <div className="range-slider">
                            <label>Prezzo</label>
                            <Slider filterSearch={this} name={"prezzo"} minValue={1} maxValue={1000000} />
                            <div className="clearfix"></div>
                        </div>
                        

                        <div className="form-group mb-0">
                            <button className="search-button">Filtra</button>
                        </div>
                    </form>
                </div>
                <div className="sidebar-widget category-posts">
                    <div className="main-title-2">
                        <h1>Filtri popolari</h1>
                    </div>
                    <ul className="list-unstyled list-cat">
                        <li><a href="#">Bilocali a meno di 100.000€</a></li>
                        <li><a href="#">Trilocali a meno di 150.000€</a></li>
                        <li><a href="#">Casa indipendente a meno di 300.000€</a></li>
                        <li><a href="#">Villa indipendente a meno di 600.000€</a></li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default Filters;