import React from 'react';
import InputRange from 'react-input-range';
import styled from 'styled-components';
import "react-input-range/lib/css/index.css";



class Slider extends React.Component {
    constructor(props) {
        super(props);
    
        this.state = {
            value: { 
                min: this.props.minValue, 
                max: this.props.maxValue
            },
        };
    }
    
    render() {
        const SliderContainer = styled.div`
            .input-range__track--active,
            .input-range__slider {
                background: ${props => props.color}; 
                border-color: ${props => props.color};
            }
        `;

        return (
            <SliderContainer color="#95c41f">
                <InputRange
                    maxValue={this.props.maxValue}
                    minValue={this.props.minValue}
                    value={this.state.value}
                    onChange={value => { 
                        this.setState({ value });
                        this.props.filterSearch.sliders[this.props.name] = value;
                    }}
                    formatLabel={value => value != 5 ? `${value}` : `${value} o più`}
                />
            </SliderContainer>
        );
    }
}

export default Slider;