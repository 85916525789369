import React from 'react';
import { Redirect } from 'react-router';
import Navigation from './Navigation.js';
import Footer from './Footer.js';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import { Tooltip, ResponsiveContainer, LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';

import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import iconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import icon from 'leaflet/dist/images/marker-icon.png';
import shadow from 'leaflet/dist/images/marker-shadow.png';
import Page from 'react-page-loading'

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: iconRetina,
    iconUrl: icon,
    shadowUrl: shadow
});

class Listing extends React.Component {

    constructor() {
        super();
        this.state = { info: null };
        this.provinces = [
            {
              "nome": "Agrigento",
              "sigla": "AG",
              "regione": "Sicilia"
            },
            {
              "nome": "Alessandria",
              "sigla": "AL",
              "regione": "Piemonte"
            },
            {
              "nome": "Ancona",
              "sigla": "AN",
              "regione": "Marche"
            },
            {
              "nome": "Arezzo",
              "sigla": "AR",
              "regione": "Toscana"
            },
            {
              "nome": "Ascoli Piceno",
              "sigla": "AP",
              "regione": "Marche"
            },
            {
              "nome": "Asti",
              "sigla": "AT",
              "regione": "Piemonte"
            },
            {
              "nome": "Avellino",
              "sigla": "AV",
              "regione": "Campania"
            },
            {
              "nome": "Bari",
              "sigla": "BA",
              "regione": "Puglia"
            },
            {
              "nome": "Barletta-Andria-Trani",
              "sigla": "BT",
              "regione": "Puglia"
            },
            {
              "nome": "Belluno",
              "sigla": "BL",
              "regione": "Veneto"
            },
            {
              "nome": "Benevento",
              "sigla": "BN",
              "regione": "Campania"
            },
            {
              "nome": "Bergamo",
              "sigla": "BG",
              "regione": "Lombardia"
            },
            {
              "nome": "Biella",
              "sigla": "BI",
              "regione": "Piemonte"
            },
            {
              "nome": "Bologna",
              "sigla": "BO",
              "regione": "Emilia-Romagna"
            },
            {
              "nome": "Bolzano/Bozen",
              "sigla": "BZ",
              "regione": "Trentino-Alto Adige/Südtirol"
            },
            {
              "nome": "Brescia",
              "sigla": "BS",
              "regione": "Lombardia"
            },
            {
              "nome": "Brindisi",
              "sigla": "BR",
              "regione": "Puglia"
            },
            {
              "nome": "Cagliari",
              "sigla": "CA",
              "regione": "Sardegna"
            },
            {
              "nome": "Caltanissetta",
              "sigla": "CL",
              "regione": "Sicilia"
            },
            {
              "nome": "Campobasso",
              "sigla": "CB",
              "regione": "Molise"
            },
            {
              "nome": "Carbonia-Iglesias",
              "sigla": "CI",
              "regione": "Sardegna"
            },
            {
              "nome": "Caserta",
              "sigla": "CE",
              "regione": "Campania"
            },
            {
              "nome": "Catania",
              "sigla": "CT",
              "regione": "Sicilia"
            },
            {
              "nome": "Catanzaro",
              "sigla": "CZ",
              "regione": "Calabria"
            },
            {
              "nome": "Chieti",
              "sigla": "CH",
              "regione": "Abruzzo"
            },
            {
              "nome": "Como",
              "sigla": "CO",
              "regione": "Lombardia"
            },
            {
              "nome": "Cosenza",
              "sigla": "CS",
              "regione": "Calabria"
            },
            {
              "nome": "Cremona",
              "sigla": "CR",
              "regione": "Lombardia"
            },
            {
              "nome": "Crotone",
              "sigla": "KR",
              "regione": "Calabria"
            },
            {
              "nome": "Cuneo",
              "sigla": "CN",
              "regione": "Piemonte"
            },
            {
              "nome": "Enna",
              "sigla": "EN",
              "regione": "Sicilia"
            },
            {
              "nome": "Fermo",
              "sigla": "FM",
              "regione": "Marche"
            },
            {
              "nome": "Ferrara",
              "sigla": "FE",
              "regione": "Emilia-Romagna"
            },
            {
              "nome": "Firenze",
              "sigla": "FI",
              "regione": "Toscana"
            },
            {
              "nome": "Foggia",
              "sigla": "FG",
              "regione": "Puglia"
            },
            {
              "nome": "Forlì-Cesena",
              "sigla": "FC",
              "regione": "Emilia-Romagna"
            },
            {
              "nome": "Frosinone",
              "sigla": "FR",
              "regione": "Lazio"
            },
            {
              "nome": "Genova",
              "sigla": "GE",
              "regione": "Liguria"
            },
            {
              "nome": "Gorizia",
              "sigla": "GO",
              "regione": "Friuli-Venezia Giulia"
            },
            {
              "nome": "Grosseto",
              "sigla": "GR",
              "regione": "Toscana"
            },
            {
              "nome": "Imperia",
              "sigla": "IM",
              "regione": "Liguria"
            },
            {
              "nome": "Isernia",
              "sigla": "IS",
              "regione": "Molise"
            },
            {
              "nome": "L'Aquila",
              "sigla": "AQ",
              "regione": "Abruzzo"
            },
            {
              "nome": "La Spezia",
              "sigla": "SP",
              "regione": "Liguria"
            },
            {
              "nome": "Latina",
              "sigla": "LT",
              "regione": "Lazio"
            },
            {
              "nome": "Lecce",
              "sigla": "LE",
              "regione": "Puglia"
            },
            {
              "nome": "Lecco",
              "sigla": "LC",
              "regione": "Lombardia"
            },
            {
              "nome": "Livorno",
              "sigla": "LI",
              "regione": "Toscana"
            },
            {
              "nome": "Lodi",
              "sigla": "LO",
              "regione": "Lombardia"
            },
            {
              "nome": "Lucca",
              "sigla": "LU",
              "regione": "Toscana"
            },
            {
              "nome": "Macerata",
              "sigla": "MC",
              "regione": "Marche"
            },
            {
              "nome": "Mantova",
              "sigla": "MN",
              "regione": "Lombardia"
            },
            {
              "nome": "Massa-Carrara",
              "sigla": "MS",
              "regione": "Toscana"
            },
            {
              "nome": "Matera",
              "sigla": "MT",
              "regione": "Basilicata"
            },
            {
              "nome": "Medio Campidano",
              "sigla": "VS",
              "regione": "Sardegna"
            },
            {
              "nome": "Messina",
              "sigla": "ME",
              "regione": "Sicilia"
            },
            {
              "nome": "Milano",
              "sigla": "MI",
              "regione": "Lombardia"
            },
            {
              "nome": "Modena",
              "sigla": "MO",
              "regione": "Emilia-Romagna"
            },
            {
              "nome": "Monza e della Brianza",
              "sigla": "MB",
              "regione": "Lombardia"
            },
            {
              "nome": "Napoli",
              "sigla": "NA",
              "regione": "Campania"
            },
            {
              "nome": "Novara",
              "sigla": "NO",
              "regione": "Piemonte"
            },
            {
              "nome": "Nuoro",
              "sigla": "NU",
              "regione": "Sardegna"
            },
            {
              "nome": "Ogliastra",
              "sigla": "OG",
              "regione": "Sardegna"
            },
            {
              "nome": "Olbia-Tempio",
              "sigla": "OT",
              "regione": "Sardegna"
            },
            {
              "nome": "Oristano",
              "sigla": "OR",
              "regione": "Sardegna"
            },
            {
              "nome": "Padova",
              "sigla": "PD",
              "regione": "Veneto"
            },
            {
              "nome": "Palermo",
              "sigla": "PA",
              "regione": "Sicilia"
            },
            {
              "nome": "Parma",
              "sigla": "PR",
              "regione": "Emilia-Romagna"
            },
            {
              "nome": "Pavia",
              "sigla": "PV",
              "regione": "Lombardia"
            },
            {
              "nome": "Perugia",
              "sigla": "PG",
              "regione": "Umbria"
            },
            {
              "nome": "Pesaro e Urbino",
              "sigla": "PU",
              "regione": "Marche"
            },
            {
              "nome": "Pescara",
              "sigla": "PE",
              "regione": "Abruzzo"
            },
            {
              "nome": "Piacenza",
              "sigla": "PC",
              "regione": "Emilia-Romagna"
            },
            {
              "nome": "Pisa",
              "sigla": "PI",
              "regione": "Toscana"
            },
            {
              "nome": "Pistoia",
              "sigla": "PT",
              "regione": "Toscana"
            },
            {
              "nome": "Pordenone",
              "sigla": "PN",
              "regione": "Friuli-Venezia Giulia"
            },
            {
              "nome": "Potenza",
              "sigla": "PZ",
              "regione": "Basilicata"
            },
            {
              "nome": "Prato",
              "sigla": "PO",
              "regione": "Toscana"
            },
            {
              "nome": "Ragusa",
              "sigla": "RG",
              "regione": "Sicilia"
            },
            {
              "nome": "Ravenna",
              "sigla": "RA",
              "regione": "Emilia-Romagna"
            },
            {
              "nome": "Reggio di Calabria",
              "sigla": "RC",
              "regione": "Calabria"
            },
            {
              "nome": "Reggio nell'Emilia",
              "sigla": "RE",
              "regione": "Emilia-Romagna"
            },
            {
              "nome": "Rieti",
              "sigla": "RI",
              "regione": "Lazio"
            },
            {
              "nome": "Rimini",
              "sigla": "RN",
              "regione": "Emilia-Romagna"
            },
            {
              "nome": "Roma",
              "sigla": "RM",
              "regione": "Lazio"
            },
            {
              "nome": "Rovigo",
              "sigla": "RO",
              "regione": "Veneto"
            },
            {
              "nome": "Salerno",
              "sigla": "SA",
              "regione": "Campania"
            },
            {
              "nome": "Sassari",
              "sigla": "SS",
              "regione": "Sardegna"
            },
            {
              "nome": "Savona",
              "sigla": "SV",
              "regione": "Liguria"
            },
            {
              "nome": "Siena",
              "sigla": "SI",
              "regione": "Toscana"
            },
            {
              "nome": "Siracusa",
              "sigla": "SR",
              "regione": "Sicilia"
            },
            {
              "nome": "Sondrio",
              "sigla": "SO",
              "regione": "Lombardia"
            },
            {
              "nome": "Taranto",
              "sigla": "TA",
              "regione": "Puglia"
            },
            {
              "nome": "Teramo",
              "sigla": "TE",
              "regione": "Abruzzo"
            },
            {
              "nome": "Terni",
              "sigla": "TR",
              "regione": "Umbria"
            },
            {
              "nome": "Torino",
              "sigla": "TO",
              "regione": "Piemonte"
            },
            {
              "nome": "Trapani",
              "sigla": "TP",
              "regione": "Sicilia"
            },
            {
              "nome": "Trento",
              "sigla": "TN",
              "regione": "Trentino-Alto Adige/Südtirol"
            },
            {
              "nome": "Treviso",
              "sigla": "TV",
              "regione": "Veneto"
            },
            {
              "nome": "Trieste",
              "sigla": "TS",
              "regione": "Friuli-Venezia Giulia"
            },
            {
              "nome": "Udine",
              "sigla": "UD",
              "regione": "Friuli-Venezia Giulia"
            },
            {
              "nome": "Valle d'Aosta/Vallée d'Aoste",
              "sigla": "AO",
              "regione": "Valle d'Aosta/Vallée d'Aoste"
            },
            {
              "nome": "Varese",
              "sigla": "VA",
              "regione": "Lombardia"
            },
            {
              "nome": "Venezia",
              "sigla": "VE",
              "regione": "Veneto"
            },
            {
              "nome": "Verbano-Cusio-Ossola",
              "sigla": "VB",
              "regione": "Piemonte"
            },
            {
              "nome": "Vercelli",
              "sigla": "VC",
              "regione": "Piemonte"
            },
            {
              "nome": "Verona",
              "sigla": "VR",
              "regione": "Veneto"
            },
            {
              "nome": "Vibo Valentia",
              "sigla": "VV",
              "regione": "Calabria"
            },
            {
              "nome": "Vicenza",
              "sigla": "VI",
              "regione": "Veneto"
            },
            {
              "nome": "Viterbo",
              "sigla": "VT",
              "regione": "Lazio"
            }
        ];
    }

    componentDidMount() {
        let prop = localStorage.getItem("property:" + this.props.match.params.id);

        if (prop != null) {
            let property = JSON.parse(prop);

            if (Date.now() - property["ts"] < 600000) {
                this.setState({
                    info: property["data"]["listings"][0],
                    others: property["data"]["listings"]
                })
                return;
            }
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "postcode": this.props.match.params.province, "id": this.props.match.params.id })
        };

        fetch('https://api.micromap.it/search', requestOptions)
            .then(res => { 
                if (res.status >= 300) {
                    this.setState({ info: false });
                } else {
                    return res.json();
                }
            }).then(result => {
                if (result && "listings" in result) {
                    localStorage.setItem("property:" + this.props.match.params.id, JSON.stringify({ "data": result, "ts": Date.now() }));

                    this.setState({
                        info: result["listings"][0],
                        others: result["listings"]
                    });
                }
            });
    }

    render() {
        const houseType = {
            "apartment": "Appartamento",
            "unknown": "Proprietà",
            "villa": "Villa",
            "sidevilla": "Villa a schiera",
            "rustic": "Rustico",
            "attic": "Attico",
            "multihouse": "Casa multifamigliare",
            "portion": "Porzione di casa",
            "palace": "Palazzo"
        };

        const contractType = {
            "1": "vendita",
            "2": "affitto"
        };

        const sitesURL = {
            "Casa": "https://images-1.casa.it/700x500",
            "Immobiliare": ""
        };

        if (this.state.info == null) {
            return <><Page loader={"rotate-spin"} color={"#95c41f"} size={8} /></>;
        }

        if (this.state.info == false){
            return <Redirect to="/pagina-non-trovata"  />;
        }

        let provinceFull = '';

        for (let i = 0; i < this.provinces.length; i++) {
            if (this.provinces[i].sigla == this.state.info.province) {
                provinceFull = this.provinces[i].nome;
            }
        }
        
        let position = <></>;
        let priceHistory = <></>;

        if (this.state.info.lat && this.state.info.long) {
            let pos = [parseFloat(this.state.info.lat), parseFloat(this.state.info.long)];
            position = <div className="location sidebar-widget">
                            <div className="map">
                                <div className="main-title-2">
                                    <h1><span>Posizione</span></h1>
                                </div>
                                <div className="map__container">
                                    <MapContainer style={{ height: "450px", width: "100%" }} center={pos} zoom={15}>
                                        <Marker position={pos}>
                                        </Marker>
                                        <TileLayer attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                    </MapContainer>
                                </div>
                            </div>
                        </div>;
        }

        if (this.state.info.price.length > 1) {
            let data = [];
            for (let i = 0; i < this.state.info.price.length; i++) {
                data.push({ name: this.state.info.price[i].ts.split(' ')[0], Prezzo: this.state.info.price[i].price });
            }
            priceHistory = 
                <div className="sidebar-widget helping-box clearfix">
                    <div className="main-title-2">
                        <h1>Andamento prezzo</h1>
                    </div>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart  data={data}>
                            <Line type="monotone" dataKey="Prezzo" stroke="#8884d8" />
                            <CartesianGrid stroke="#ccc" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip formatter={(value) => new Intl.NumberFormat('it').format(value) + '€'} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>    
        }

        console.log(this.state.info);
        return (
            <>
            <Page loader={"rotate-spin"} color={"#95c41f"} size={8}>
                <Navigation />
                <div className="properties-details-page content-area-17">
                    <div className="container">
                        <div className="row mb-20">
                            <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="heading-properties clearfix sidebar-widget">
                                    <div className="pull-left">
                                        <p>
                                            Proprietà in {contractType[this.state.info.contract]} <i className="fa fa-angle-right" style={{ paddingLeft: 5 + "px", paddingRight: -1 + "px" }} /> Provincia di {provinceFull}
                                        </p>
                                    </div>
                                </div>
                                <div className="sidebar-widget mb-30">
                                    <div className="heading-properties clearfix mb-30">
                                        <div className="pull-left">
                                            <h3>{houseType[this.state.info.type]} a {this.state.info.city}</h3>
                                            <p>
                                                <i className="fa fa-map-marker"></i>{this.state.info.address ? this.state.info.address : this.state.info.city}
                                            </p>
                                        </div>
                                        <div className="pull-right">
                                            <h3><span>€ {new Intl.NumberFormat('it-IT').format(this.state.info.price[this.state.info.price.length-1].price)}</span></h3>
                                            <h5>
                                            
                                            </h5>
                                        </div>
                                    </div>
                                    <Carousel>
                                        <div>
                                            <img src={sitesURL[this.state.info.site] + this.state.info.pics[0]} />
                                        </div>
                                        <div>
                                            <img src={sitesURL[this.state.info.site] + this.state.info.pics[1]} />
                                        </div>
                                        <div>
                                            <img src={sitesURL[this.state.info.site] + this.state.info.pics[2]} />
                                        </div>
                                    </Carousel>
             
                                    <div className="properties-condition  ">
                                        <div className="main-title-2">
                                            <h1><span>Caratteristiche</span></h1>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                                <ul className="condition">
                                                    <li>
                                                        <i className="flaticon-bed"></i>{this.state.info.rooms} locali
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                                <ul className="condition">
                                                    <li>
                                                        <i className="flaticon-square-layouting-with-black-square-in-east-area"></i>{this.state.info.surface} mq
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-4 col-sm-4 col-xs-12">
                                                <ul className="condition">
                                                    <li>
                                                        <i className="flaticon-vehicle"></i>1 Garage
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                    
                                </div>

                                <div className="sidebar-widget helping-box clearfix">
                                        <div className="main-title-2">
                                            <h1>Disponibile su</h1>
                                        </div>
                                        <div className="helping-center">
                                            <div className="icon" style={{paddingRight: 15 + 'px'}}><i className="fa fa-link"></i></div>
                                            <h4>Casa.it</h4>
                                            <span>{this.state.info.url}</span>
                                        </div>
                                        <div className="helping-center">
                                            <div className="icon" style={{paddingRight: 15 + 'px'}}><i className="fa fa-link"></i></div>
                                            <h4>Immobiliare.it</h4>
                                            <span>{this.state.info.url}</span>
                                        </div>
                                </div>
                                {priceHistory}
                                {position}        
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                <div className="sidebar right">

                                    <div className="sidebar-widget contact-1 mortgage-calculator">
                                        <div className="main-title-2">
                                            <h1><span>Simula il mutuo con </span> Agos!</h1>
                                        </div>
                                        <div className="contact-form">
                                            <form id="agent_form" action="index.html" method="GET" encType="multipart/form-data">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Tasso d'interesse (%)</label>
                                                            <input type="text" className="input-text" placeholder="10%"  />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Durata in anni</label>
                                                            <input type="text" className="input-text" placeholder="20 anni" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Anticipo</label>
                                                            <input type="text" className="input-text" placeholder="€35.000" />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="form-group mb-0">
                                                            <button className="search-button">Calcola</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>

                                    <div className="inside-properties sidebar-widget">
                                        <div className="main-title-2">
                                            <h1><span>Pubblicità</span></h1>
                                        </div>
                                        <iframe className="modalIframe" src="https://www.youtube.com/embed/xywe1MxGxKw" ></iframe>
                                    </div>

                                    <div className="social-media sidebar-widget clearfix">
                                        <div className="main-title-2">
                                            <h1><span>Condividi</span></h1>
                                        </div>
                                        <ul className="social-list">
                                            <li><a href="#" className="facebook-bg"><i className="fa fa-facebook"></i></a></li>
                                            <li><a href="#" className="twitter-bg"><i className="fa fa-twitter"></i></a></li>
                                            <li><a href="#" className="linkedin-bg"><i className="fa fa-linkedin"></i></a></li>
                                            <li><a href="#" className="google-bg"><i className="fa fa-google-plus"></i></a></li>
                                            <li><a href="#" className="rss-bg"><i className="fa fa-rss"></i></a></li>
                                        </ul>
                                    </div>

                            
                                </div>
                            </div>
                        </div>

                        <div className="main-title-2">
                            <h1><span>Annunci correlati</span></h1>
                        </div>
                        <div className="row">
                            {this.state.others.map((property, i) => i != 0 &&
                                <div className="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                                    <div className="property-2">
                                        <div className="property-inner">
                                            <div className="property-overflow">
                                                <div className="property-photo">
                                                    <img src={sitesURL[property.site] + property.pics[0]} alt="rp" className="img-responsive"  />
                                                    <div className="price-ratings">
                                                        <div className="price">€ {new Intl.NumberFormat('it-IT').format(property.price[0].price)}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="content">
                                                <h4 className="title">
                                                    <a href={`/listing/${property.postcode}/${property.id}`}>{houseType[property.type]} a {property.city}</a>
                                                </h4>
                                                <h3 className="property-address">
                                                    <a href={`/listing/${property.postcode}/${property.id}`}>
                                                        <i className="fa fa-map-marker"></i> {property.address}
                                                    </a>
                                                </h3>
                                            </div>
                                            <ul className="facilities-list clearfix">
                                                <li>
                                                    <i className="flaticon-square-layouting-with-black-square-in-east-area"></i> <span>{property.surface} mq</span>
                                                </li>
                                                <li>
                                                    <i className="flaticon-bed"></i> <span>{property.rooms} locali</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <Footer />
            </Page>          
            </>
        );
    }
}

export default Listing;