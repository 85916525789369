import React from 'react';

class SearchResult extends React.Component {
    sitesURL = {
        "Casa": "https://images-1.casa.it/800x625",
        "Immobiliare": "https://www.immobiliare.it/"
    };

    houseType = {
        "apartment": "Appartamento",
        "unknown": "Proprietà",
        "villa": "Villa",
        "sidevilla": "Villa a schiera",
        "rustic": "Rustico",
        "attic": "Attico",
        "multihouse": "Casa multifamigliare",
        "portion": "Porzione di casa",
        "palace": "Palazzo"
    };

    getThumbnail() {
        if (this.props.site == 'Casa') {
            return this.sitesURL[this.props.site] + this.props.pic;
        } else {
            return this.props.pic;
        }
    }

    render() {

        /* const houseType = {
            0: "Appartamento",
            1: "Monolocale",
            2: "Bilocale",
            3: "Trilocale",
            4: "Quadrilocale"
        }; */

        

        function getDateFromString(str) { 
            var t, result = null;

            if(typeof str === 'string') {
                t = str.split(/[- :]/);
                result = new Date(t[0], t[1] - 1, t[2], t[3] || 0, t[4] || 0, t[5] || 0);          
            }

            return result;   
        }

        function timeSince(date) {
            var seconds = Math.floor((new Date() - date) / 1000);
            var interval = seconds / 31536000;
            if (interval > 1) return Math.floor(interval) + " anni";
            interval = seconds / 2592000;
            if (interval > 1) return Math.floor(interval) + " mesi";
            interval = seconds / 86400;
            if (interval > 1) return Math.floor(interval) + " giorni";
            interval = seconds / 3600;
            if (interval > 1) return Math.floor(interval) + " ore";
            interval = seconds / 60;
            if (interval > 1) return Math.floor(interval) + " minuti";
            return Math.floor(seconds) + " secondi";
        }

        return (
            <div className="property fp2 clearfix wow fadeInUp delay-03s">
                <div className="col-lg-5 col-md-5 col-sm-5 col-xs-12 col-pad">
                    <div className="property-img">
                        {this.props.deal && <div className="property-tag button alt featured">Occasione</div>}
                        <div className="property-price">€ {new Intl.NumberFormat('it-IT').format(this.props.price)}</div>
                        <img src={this.getThumbnail()} alt="fp-list" className="img-responsive hp-1" />
                        <div className="property-overlay">
                            <a href="properties-details.html" className="overlay-link">
                                <i className="fa fa-link"></i>
                            </a>
                            <div className="property-magnify-gallery">
                                <a href="img/properties/properties-1.png" className="overlay-link">
                                    <i className="fa fa-expand"></i>
                                </a>
                                <a href="img/properties/properties-2.png" className="hidden"></a>
                                <a href="img/properties/properties-3.png" className="hidden"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-7 col-xs-12 property-content ">
                    <div className="info">
                        <h1 className="title">
                            <a href={`/listing/${this.props.postcode}/${this.props.id}`}>{this.houseType[this.props.type]} a {this.props.city}</a>
                        </h1>
                        <h3 className="property-address">
                            <a href="properties-details.html">
                                <i className="fa fa-map-marker"></i> {this.props.address != '' ? this.props.address : this.props.city}
                            </a>
                        </h3>
                        <ul className="facilities-list clearfix">
                            <li key="mq">
                                <i className="flaticon-square-layouting-with-black-square-in-east-area"></i> <span>{this.props.mq} mq</span>
                            </li>
                            <li key="bedrooms">
                                <i className="flaticon-bed"></i> <span>{this.props.rooms} {this.props.rooms > 1 ? 'locali' : 'locale'} </span>
                            </li>
                        </ul>
                    </div>
                    <div className="property-footer">
                        <span className="left">
                            <a href="#"><i className="fa fa-search"></i> {this.props.site}</a>
                        </span>
                        <span className="right">
                            <i className="fa fa-calendar"></i>{timeSince(getDateFromString(this.props.date))}
                        </span>
                    </div>
                </div>
            </div>
        );
    }
}

export default SearchResult;