import React from 'react';
import Logo from './assets/img/logo.png';
import { Redirect } from 'react-router-dom';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.logIn = this.logIn.bind(this);
        this.state = { error: 0, fetching: false, redirect: false };
    }

    async componentDidMount() {
        
    }

    async logIn(e) {
        e.preventDefault();

        const email = e.target.email.value;
        const password = e.target.password.value;

        if (email == "" || password == "" ) {
            this.setState({ error: 1 });
            return;
        }

        this.setState({ fetching: true });
    
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "action": "login",
                "email": email,
                "password": password
            })
        };
        
        const response = await fetch('https://api.micromap.it/auth', requestOptions);
        const result = await response.json();

        if (!("status" in result)) {
            NotificationManager.error("Qualcosa è andato storto: aggiorna la pagina o riprova più tardi!", '', 5000, () => {});
            this.setState({ fetching: false });
        } else {

            if (result["status"] == "err" && !("message" in result)) {
                NotificationManager.error("Qualcosa è andato storto: aggiorna la pagina o riprova più tardi!", '', 5000, () => {});
                this.setState({ fetching: false });
                return;
            }

            if ("message" in result && result["message"] == "invalid") {
                this.setState({ error: 2, fetching: false});
                return;
            } 
            
            if (result["status"] == "ok") {
                localStorage.setItem("authToken", result["token"]);
                this.setState({ redirect: true });
                return;
            }
        }
    }

    render() {
        return (
            <>
                {this.state.redirect && <Redirect to="/"/>}
                <div className="login-section">
                    <div className="form-content-box">
                        <div className="details">
                            <div className="logo">
                                <a href="/">
                                    <img src={Logo} alt="logo" />
                                </a>
                            </div>
                            <div className="clearfix"></div>
                                <h3>Entra nel tuo account</h3>
                                <form onSubmit={this.logIn}>
                                    <div className="form-group">
                                        <input type="email" name="email" className="input-text" placeholder="Indirizzo email" />
                                    </div>
                                    <div className="form-group">
                                        <input type="password" name="password" className="input-text" placeholder="Password" />
                                    </div>
                                    <div className="checkbox">
                                        <div className="ez-checkbox pull-left">
                                            <label>
                                                <input type="checkbox" className="ez-hide" />
                                                Ricordami
                                            </label>
                                        </div>
                                        <a href="forgot-password.html" className="pull-right">Password dimenticata</a>
                                        <div className="clearfix"></div>
                                    </div>
                                    {this.state.error == 1 && <div className="alert alert-danger" role="alert">
                                        Ci sono uno o più campi vuoti
                                    </div>}
                                    {this.state.error == 2 && <div className="alert alert-danger" role="alert">
                                        Indirizzo email o password errati
                                    </div>}
                                    <div className="form-group">
                                        <button type="submit" className="button-md button-theme btn-block btn-3">{!this.state.fetching ? "Login" : <i className="fa fa-circle-o-notch fa-spin"></i>}</button>
                                    </div>
                                    
                                </form>
                            </div>
                            <div className="footer">
                            <span>
                                Non hai un account? <a href="/register">Registrati qui</a>
                            </span>
                        </div>
                    </div>
                </div>
                <NotificationContainer />
            </>
        );
    }
}

export default Login;