import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import SearchPage from './SearchPage.js';
import ResultsPage from './ResultsPage.js';
import LoginPage from './LoginPage.js';
import RegisterPage from './RegisterPage.js';
import ListingPage from './ListingPage.js';
import ErrorPage from './ErrorPage.js';
import ZonePage from './ZonePage.js';

// import DrawPage from './Draw.js';
import './index.css';
import './assets/css/bootstrap.min.css';
import './assets/css/style.css';
import './assets/css/default.css';
import './assets/fonts/font-awesome/css/font-awesome.min.css';
import './assets/fonts/flaticon/font/flaticon.css';
import './assets/fonts/linearicons/style.css';

function Micromap() {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={SearchPage} />
                <Route exact path="/login" component={LoginPage} />
                <Route exact path="/register" component={RegisterPage} />
                <Route exact path="/results/:contract/:id/:page" component={ResultsPage} />
                <Route exact path="/listing/:province/:id" component={ListingPage} />
                <Route exact path="/zone/:id" component={ZonePage} />
                <Route exact path="/pagina-non-trovata" component={ErrorPage} />
                <Redirect to="/" />
            </Switch>
        </Router>
    );
}

export default Micromap;
