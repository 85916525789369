import React from 'react';

class ErrorPage extends React.Component {
    render() {
        return (
            <div class="error-page">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-7">
                            <div class="error404-content">
                                <div class="error404">404</div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-5">
                            <div class="nobottomborder">
                                <h1>Oops!<br /><br />Non troviamo quello che stai cercando, sembrerebbe che tu abbia usato un link non corretto</h1>
                                <p>Ritorna alla homepage di Micromap</p>
                                <a class="btn-1 btn-outline-1" href="/">
                                    <span>Homepage</span> <i class="arrow"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorPage;